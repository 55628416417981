/* Apply a smooth gradient background and center all content */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(
    135deg,
    #007bff,
    #28a745
  ); /* Green and blue gradient */
  color: white;
  overflow: hidden;
}

/* Main app container */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 400px; /* Limit max width of the login form */
  padding: 20px;
  box-sizing: border-box;
  min-height: 300px; /* Ensure it doesn't shrink too small */
  position: relative;
}

/* Heading styles */
h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #fff;
  font-weight: bold;
}

h2 {
  font-size: 2em;
  color: #fff;
}

/* Form container */
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  background: rgba(
    0,
    0,
    0,
    0.6
  ); /* Semi-transparent black background for form */
  border-radius: 15px; /* More rounded corners */
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3); /* Softer shadow for a floating effect */
  position: relative;
}

/* Input field styles (removing white background) */
input {
  width: 100%;
  max-width: 350px; /* Max width for inputs to prevent them from stretching too much */
  padding: 12px 15px; /* Adjust padding for a cleaner look */
  font-size: 1em;
  border-radius: 12px; /* Smoother rounded corners */
  border: 1px solid #ddd; /* Lighter border to make it feel less harsh */
  outline: none;
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Semi-transparent light background */
  color: #e0e0e0; /* Light grey text color for better readability */
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1); /* Softer inner shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for border color and box shadow */
}

/* Button styles */
button {
  width: 100%;
  max-width: 350px; /* Max width for button to match inputs */
  padding: 12px;
  font-size: 1.1em; /* Slightly smaller font size */
  font-weight: 500; /* Medium weight font for a more subtle appearance */
  border: none;
  border-radius: 12px; /* Rounded button corners */
  background: linear-gradient(
    145deg,
    #0062cc,
    #007bff
  ); /* Modern blue gradient */
  color: white;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for background, shadow, and transform */
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); /* Soft shadow for depth */
  text-transform: none; /* Avoid uppercase for a more natural feel */
  letter-spacing: 0.5px; /* Slight spacing between characters for a modern, clean look */
  text-align: center;
}

button:hover {
  background: linear-gradient(
    145deg,
    #0056b3,
    #0069d9
  ); /* Darker blue gradient on hover */
  box-shadow: 0 6px 12px rgba(0, 123, 255, 0.3); /* More prominent shadow on hover */
  transform: translateY(-2px); /* Subtle lift effect */
}

button:active {
  background: linear-gradient(
    145deg,
    #004085,
    #0056b3
  ); /* Even darker blue on active state */
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2); /* Subtle shadow on active */
  transform: translateY(0px); /* Reset transform on click */
}

button:disabled {
  background: #cccccc;
  cursor: not-allowed;
  box-shadow: none; /* Remove shadow for disabled state */
}

/* Subtle error message container */
.error-message {
  background: rgba(255, 93, 93, 0.15); /* Less transparent red background */
  color: #b02d2d; /* Muted dark red for text */
  border-left: 4px solid rgba(255, 93, 93, 0.6); /* Light red border */
  border-radius: 8px;
  padding: 8px 15px; /* Compact padding */
  margin-top: 10px; /* Space between input fields and error message */
  font-size: 0.95em;
  font-weight: 400; /* Light font weight */
  width: 100%;
  max-width: 400px; /* Max width for better alignment */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  z-index: 10;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease;
  word-wrap: break-word; /* Ensures text wraps properly */
  text-align: center; /* Center the text inside the box */
}

/* Error message fade-out on close */
.error-message.fade-out {
  opacity: 0;
  transform: translateY(-10px); /* Subtle slide up effect */
  pointer-events: none; /* Prevent click events after fade-out */
}

/* Success message container */
.success-message {
  background: rgba(40, 167, 69, 0.15); /* Light green background */
  color: #28a745; /* Green text */
  border-left: 4px solid rgba(40, 167, 69, 0.6); /* Green border */
  border-radius: 8px;
  padding: 8px 15px;
  margin-top: 10px;
  font-size: 0.95em;
  font-weight: 400;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: slideIn 0.3s ease-in-out;
}

/* Error message fade-out on close */
.success-message.fade-out {
  opacity: 0;
  transform: translateY(-10px); /* Subtle slide up effect */
  pointer-events: none; /* Prevent click events after fade-out */
}

/* Animation for the error message */
@keyframes slideIn {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Paragraph and link styles */
p {
  color: #fff;
  margin-top: 20px;
}

a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

.login-page,
.create-account-page,
.homepage {
  margin: 0 auto;
}

.homepage p {
  margin-top: 10px;
}

footer {
  margin-top: 30px;
  color: #fff;
  font-size: 0.9em;
}

/* MOBILE RESPONSIVENESS */
@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  form {
    max-width: 100%;
    padding: 15px;
  }

  input {
    font-size: 0.95em;
  }

  button {
    font-size: 1em;
  }

  p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.3em;
  }

  form {
    max-width: 100%;
    padding: 10px;
  }

  input {
    font-size: 0.9em;
  }

  button {
    font-size: 0.95em;
  }

  p {
    font-size: 0.9em;
  }

  footer {
    font-size: 0.8em;
  }
}
